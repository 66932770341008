
<template>
    <div class="project-fund-info">
        <div class="project-main_user">
            <div class="project-main_userMain">
                <img class="user-avatar" v-lazy="issuer_face_url">
                <span class="u-name">{{ issuer_name }}</span>
                <span class="common-title text-grey">发起项目</span>

                <button class="share-btn" v-if="!is_manager" @click="reportProject">我也要筹款</button>
            </div>
        </div>
        <h1 class="project-main_title">{{ project_title }}</h1>
        <div class="progress-wrap" >
            <div class="progress-inner" :style="{'width': fundProgress()+'%'}"></div>
            <div class="progress-pointer J-progress-pointer"></div>
        </div>

        <ul class="moneylist">
            <li>
                <span>目标金额(元)</span>
                <span class="item-num">{{targetAmount}}</span>
            </li>
            <li>
                <span >已筹金额(元)</span>
                <span class="item-num">{{raiseAmount}}</span>
            </li>
            <li>
                <span>进度</span>
                <span class="item-num">{{fundProgress()}}%</span>
            </li>
        </ul>
        <ProjectVolunteer class="mg-bt10" />
    </div>
</template>

<script>
import { mapState } from 'vuex';
export default {
    name: 'ProjectBaseInfo',
    data () {
        return {}
    },
    components: {
        ProjectStory: () => import('./ProjectStory/index'),
        ProjectVolunteer: () => import("./ProjectVolunteer/index.vue"),
    },
    computed: {
        ...mapState([
            'issuer_face_url',
            'issuer_name',
            'projuuid',
            'project_title',
            'raisefunds_number',
            'accumulative_number',
            'is_manager',
        ]),

        // 已筹目标金额
        raiseAmount() {
            return this.accumulative_number/100
        },
        // 目标金额分
        targetAmount() {
            return this.raisefunds_number/100
        },
    },

    methods: {
        fundProgress() {
            const raiseAmount = this.raiseAmount;
            const targetAmount = this.targetAmount;
            let num = (Number(raiseAmount)/Number(targetAmount) ) * 100;

            if (!num) return 0;
            if (num >= 100) return 100;

            let strNum = num.toString();
            if (strNum && strNum.indexOf('.') !== -1) {
                return strNum.substring(0, strNum.indexOf('.')+3);
            }
            return strNum;
        },

        // 筹款
        reportProject() {
            this.$router.push({
                path: '/fund/publish/guide'
            })
        }
    }
}
</script>

<style lang='less' scoped>
.project-fund-info {
    padding: 15px 20px 0;
    background: #fff;
}
.project-main_user,
.project-main_userMain {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
}

 .project-main_user{
    height: 40px;
    -webkit-box-pack: justify;
    font-size: 18px;
    color: #4a4a4a;
}

 .project-main_userMain{
    -webkit-box-flex: 1;
    -ms-flex: 1;
    flex: 1;
    position: relative;
    .user-avatar {
        width: 22px;
        height: 22px;
        margin-right: 5px;
        border-radius: 11px;
    }
    span {
        font-size: 14px;
    }
    .u-name{
        margin-right: 8px;
        max-width: 80px;
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 1;
        -webkit-box-orient: vertical;
        word-break: break-all;
    }
    .report {
        font-size: 13px;
        color: #999999;
        position: absolute;
        right: 0;
    }
}

.project-main_title{
    font-size: 24px;
    color: #333333;
    margin: 20px 0 20px;
    text-align: left;
    // display: -webkit-box;
    // -webkit-box-orient: vertical;
    // -webkit-line-clamp: 2;
    // overflow: hidden;
}
.progress-wrap {
    position: relative;
    margin: 0px 0px 20px;
    background-color: rgba(0,0,0,.08);
    line-height: 0;
    font-size: 0;
    height: 8px;
    border-radius: 11px;
    overflow: hidden;

    .progress-inner {
        position: absolute;
        left: 0;
        top: 0;
        height: 100%;
        border-radius: 11px;
        background-image: linear-gradient(90deg, #40B9FF 0%, #009BFF 100%);
        box-shadow: 0 4px 12px 0 rgba(0, 155, 255, 0.40);
        border-radius: 12px;
    }
}
.share-btn {
    width: 90px;
    position: absolute;
    right: 0;
    display: block;
    color: #fff;
    font-size: 12px;
    padding: 7px 8px;
    background-image: linear-gradient(90deg,#40B9FF,#009BFF);
    border-radius: 4px;
    margin-left: 10px ;
    box-shadow: 0 4px 30px 0 rgba(0,155,255,0.4);
    border: none;
}
.moneylist{
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    margin: 0 auto;
    padding: 0 0 23px 0;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
    list-style: none;
    position: relative;

    &:after {
        content: '';
        display: inline-block;
        height: 1px;
        width: 100%;
        background: #E9E9E9;
        position: absolute;
        bottom: 0;
        left: 0;
    }

    li {
        -webkit-box-flex: 1;
        -ms-flex: 1;
        flex: 1;
        text-align: center;

        span {
            display: block;
            color: #999999;
            text-align: center;
            font-size: 13px;
            line-height: 1;
        }
        span:first-child {
            margin-bottom: 8px;
        }

        .item-num {
            display: block;
            color: #333333;
            font-size: 22px;
            font-style: normal;
            line-height: 1;
            font-weight: normal;
        }
    }
}
</style>
