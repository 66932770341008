import { render, staticRenderFns } from "./ProjectBaseInfo.vue?vue&type=template&id=24d496f9&scoped=true&"
import script from "./ProjectBaseInfo.vue?vue&type=script&lang=js&"
export * from "./ProjectBaseInfo.vue?vue&type=script&lang=js&"
import style0 from "./ProjectBaseInfo.vue?vue&type=style&index=0&id=24d496f9&lang=less&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "24d496f9",
  null
  
)

export default component.exports